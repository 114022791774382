.followingTable {
  margin-bottom: 12px;
}

.followingTable thead {
  position: sticky;
  top: 0px;
  background-color: white;
}

.followingTable tr.following-row:hover {
  background-color: #efefff;
}

.followingTable tr td.hover {
  background-color: #efefff;
}

.followingTable tr th.hover {
  background-color: #efefff;
}

.followingTable tr td.nofollow {
  background-color: #ccc;
}

th.listname div.icon {
  display: inline;
  writing-mode: horizontal-tb;
  transform-origin: 12px 12px;
  transform: rotate(180deg);
  color: lightgray;
}

th.listname div.icon:hover {
  color: darkgray;
}

span.listTitle {
  writing-mode: vertical-lr;
  -webkit-transform: rotate(180deg);
  text-overflow: ellipsis;
  white-space: nowrap;
  max-height: 150px;
  overflow: hidden;
}

span.listTitle.expanded {
  max-height: none;
}

span.listTitle.newList {
  color: #77f;
  cursor: pointer;
}

td.usercell {
  padding: 0;
  padding-right: 4px;
  border-right: 1px solid lightgray;
  width: 300px;
}

td.suspended {
  background-color: #fbb;
}

td.limited {
  background-color: #ffb;
}

td.moved {
  background-color: #bff;
}
